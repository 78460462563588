import React from 'react';
import styled from 'styled-components';

const Link = ({ text, children, to, classes, styles, target }) => (
	<Component
		href={to}
		className={classes}
		styles={styles}
		target={target || '_self'}
	>
		{text}
		{children}
	</Component>
);

export default Link;

const Component = styled.a`
	${props => props?.styles}
`;
